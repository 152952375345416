@import 'colours';
@import 'fonts';

.no-gutters {
  margin-left: 0;
  margin-right: 0;

  > .col,
  > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }

  .gutter {
    padding-left: 15px;
    padding-right: 15px;
  }

  .gutter-left {
    padding-left: 15px;
  }

  .gutter-right {
    padding-right: 15px;
  }
}

.dropdown-menu {
  background-color: var(--dropdown-menu-bg-colour);
  border: var(--dropdown-menu-border);
  box-shadow: var(--dropdown-menu-box-shadow);
}

.dropdown-divider {
  border-top: var(--dropdown-divider-border);
}

.dropdown-item {
  color: var(--text-colour);
  cursor: pointer;
  font-size: .8rem;

  &:hover,
  &:focus {
    background-color: var(--dropdown-item-bg-colour);
    color: var(--text-colour);
  }
}

.btn {
  color: var(--btn-text-colour);
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: var(--btn-text-colour);
  }

  &:disabled {
    cursor: default;
  }
}

.btn-primary {
  align-items: center;
  background: $brand-secondary-colour;
  border: 0;
  color: $white;
  display: inline-flex;
  font-family: $form-controls-font-family;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  min-width: 190px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  transition: background-color .3s ease;
  vertical-align: top;
  white-space: nowrap;

  &:hover,
  &:focus,
  &.focus {
    background: darken($brand-secondary-colour, 10%);
    border: 0;
    color: $white;
  }

  &.btn-sm {
    height: auto;
    min-width: auto;
  }

  &:disabled {
    background: $brand-secondary-colour;
  }

  &:focus,
  .focus {
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .2);
  }

  &:not(:disabled):not(.disabled):active {
    background: darken($brand-secondary-colour, 10%);
    border: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .2);

    &:focus,
    &.focus {
      box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .2);
    }
  }
}

.input-group {
  .btn {
    height: calc(1.5em + .75rem + 2px);
    min-width: 0;
  }
}

a.btn {
  text-decoration: none !important;
}

.btn-secondary {
  background: var(--btn-secondary-bg-colour);
  border: 1px solid var(--btn-secondary-border-colour);
  color: var(--btn-secondary-colour);
  display: inline-block;
  font-family: $form-controls-font-family;
  font-weight: bold;
  height: 40px;
  min-width: 188px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  transition: background-color .3s ease;
  vertical-align: top;

  &:hover,
  &:not(:disabled):not(.disabled):hover,
  &:focus,
  &.focus {
    background: var(--btn-secondary-bg-hover-colour);
    border: 1px solid var(--btn-secondary-border-colour);
    color: var(--btn-secondary-text-hover-colour);
  }

  &.btn-sm {
    height: auto;
    min-width: auto;
  }

  &:focus,
  .focus {
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .2);
  }

  &:not(:disabled):not(.disabled):active {
    background-color: var(--btn-secondary-bg-hover-colour);
    border: 1px solid var(--btn-secondary-border-colour);
    color: var(--btn-secondary-text-hover-colour);
  }

  &.disabled,
  &:disabled {
    background: var(--btn-secondary-bg-colour);
    border: 1px solid var(--btn-secondary-border-colour);
    color: var(--btn-secondary-colour);
    cursor: default;
    opacity: .65;
  }
}

.btn-danger {
  background: #a91523;
  border: 0;
  color: $white;
  display: inline-block;
  font-family: $form-controls-font-family;
  font-weight: bold;
  height: 40px;
  min-width: 190px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  transition: background-color .3s ease;

  &:hover {
    background: darken(#a91523, 10%);
    color: $white;
  }

  &.btn-sm {
    height: auto;
    min-width: auto;
  }

  &.btn-subtle {
    background: var(--btn-secondary-bg-colour);
    border: 1px solid var(--btn-danger-subtle-border-colour);
    color: var(--btn-danger-subtle-colour);

    &:hover,
    &:not(:disabled):not(.disabled):hover,
    &:focus,
    &.focus {
      background: var(--btn-secondary-bg-hover-colour) !important;
      color: var(--btn-danger-subtle-hover-colour) !important;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $brand-secondary-colour;
  border: 0;
}

.progress {
  background-color: var(--progress-bg-colour);
  height: 20px;
}

.progress-bar {
  border-right: 1px solid #eee;

  &:last-child {
    border-right: 0;
  }
}

.progress-bar-success:not(.progress-bar-striped) {
  background: #018b0e linear-gradient(to bottom, #018b0e 0%, #007a0b 100%);
}

.progress-bar-success.progress-bar-striped {
  background-color: #018b0e;
}

.progress-bar-warning:not(.progress-bar-striped) {
  background: #e0a331 linear-gradient(to bottom, #e0a331 0%, #b8810c 100%);
}

.progress-bar-warning.progress-bar-striped {
  background-color: #e0a331;
}

.progress-bar-danger:not(.progress-bar-striped) {
  background: #a91523 linear-gradient(to bottom, #a91523 0%, #8f111d 100%) !important;
}

.progress-bar-danger.progress-bar-striped {
  background-color: #a91523;
}

.bs-datepicker {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
}

.radio-buttons {
  display: block;
  white-space: nowrap;

  .btn-primary {
    background: var(--radio-button-bg-colour);
    border: var(--radio-button-border);
    color: var(--radio-button-colour);
    font-weight: normal;
    line-height: 30px;
    min-width: 0;
    padding: .2rem 1rem;
    transition: none;

    &.active {
      background: $brand-secondary-colour;
      border: 1px solid $brand-secondary-colour;
      color: $white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      border: 1px solid $brand-secondary-colour;
    }
  }
}

.alert {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.alert-warning {
  background-color: var(--alert-warning-bg-colour);
  border-color: var(--alert-warning-border-colour);
  color: var(--alert-warning-colour);
}

.alert-secondary {
  background-color: var(--alert-secondary-bg-colour);
  border: var(--alert-secondary-border);
  color: var(--text-colour);
}

.close,
.close:hover {
  color: var(--modal-close-colour);
  text-shadow: 0 1px 0 var(--modal-close-shadow-colour);
}
