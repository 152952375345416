@import 'fonts';

.dropdown-list {
  overflow: hidden;
  width: auto !important;

  li { min-width: 400px; }
}

.toggle-reveal {
  font-size: 80%;
  margin-left: .5rem;
  text-decoration: none !important;
}

.btn-secondary {
  &.small-icon {
    border-radius: 3px;
    display: inline-block;
    font-size: 12px;
    height: 22px;
    min-width: 0;
    padding: 0 4px;
    position: relative;
    top: -2px;
    vertical-align: middle;
    width: auto;
  }
}

.form-control {
  background: var(--form-control-bg-colour);
  border: 1px solid var(--form-control-border-colour);
  color: var(--form-control-colour);

  &:focus {
    background: var(--form-control-bg-colour);
    border: 1px solid var(--form-control-focused-border-colour);
    color: var(--form-control-colour);
  }

  &:disabled, &[readonly] {
    background: var(--form-control-disabled-bg-colour);
    border: 1px solid var(--form-control-disabled-border-colour);
    color: var(--form-control-disabled-colour);
  }

  &::placeholder {
    color: var(--form-control-text-placeholder-colour);
  }
}

input,
select,
textarea {
  color: var(--text-colour);
  font-family: $form-controls-font-family;
}

input,
textarea,
select,
button,
.btn,
.form-control {
  box-shadow: none !important;
}

.form-text.hint {
  font-family: $form-labels-font-family;
  font-size: .8rem;
  margin-bottom: .25rem;
  margin-top: 0;

  &.for-radio-buttons {
    margin-top: .25rem;
  }
}

small.form-text.hint:not(.above) {
  margin-top: .25rem;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  margin: 2px !important;
}

table textarea.ng-invalid,
table input.ng-invalid {
  background: var(--form-table-input-invalid-bg-colour) !important;
  border: 1px solid var(--form-table-input-invalid-border-colour) !important;
}

.sticky.end-buttons {
  align-items: center;
  background: var(--end-buttons-bg-colour);
  border-top: 1px solid var(--end-buttons-border-colour);
  bottom: 0;
  display: flex;
  gap: 1rem;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 2rem 65px;
  position: sticky;
  z-index: 101;

  p.invalid {
    color: var(--text-colour-danger);
    flex-grow: 1;
    margin: 0 0 0 2rem;

    i.fa-exclamation-circle {
      font-size: 150%;
      margin-right: .2rem;
      vertical-align: middle;
    }
  }
}

.switch-with-label {
  align-items: center;
  display: flex;
  gap: .4rem;

  label {
    margin: 0;
    position: relative;
    top: 1px;
  }
}

.mimic-text-box {
  align-items: center;
  background: var(--form-control-bg-colour);
  border: 1px solid var(--form-control-border-colour);
  border-radius: .25rem;
  color: var(--text-colour);
  display: flex;
  flex-wrap: nowrap;
  font-family: $form-controls-font-family;
  gap: .5rem;
  height: 38px;
  padding: 0 .75rem;

  &.readonly {background: var(--form-control-disabled-bg-colour);}

  .value { white-space: nowrap; }

  .placeholder { color: var(--form-control-text-placeholder-colour); }

  .spacer { flex-grow: 1; }
}

input.oversized {
  font-size: 1.5rem;
}

input[type=checkbox] {
  opacity: 0;
  position: absolute;

  & + label {
    cursor: pointer;
    display: inline-block !important;
    font-family: $form-labels-font-family;
    font-weight: normal !important;
    margin: 0 !important;
    padding: 0;
    position: relative;
  }

  & + label:before {
    background: var(--form-control-checkbox-unchecked-colour);
    border-radius: 3px;
    content: '';
    display: inline-block;
    height: 16px;
    margin-right: 8px;
    position: relative;
    top: 1px;
    transition: background .1s ease;
    vertical-align: text-top;
    width: 16px;
  }

  &:hover + label:before { background: var(--form-control-checkbox-hover-colour); }

  &:checked + label:before { background: var(--form-control-checkbox-checked-colour); }

  &:disabled + label {
    cursor: auto;
    opacity: .5;
  }

  &:disabled + label:before {
    box-shadow: none;
    opacity: .5;
  }

  &:checked + label:after {
    background: #fff;
    box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
    content: '';
    height: 2px;
    left: 3px;
    position: absolute;
    top: 8px;
    transform: rotate(45deg);
    width: 2px;
  }
}

.switch {
  background: var(--switch-bg-colour);
}
