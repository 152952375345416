@import 'colours';
@import 'fonts';

h1, h2, h3, h4, h5, h6 {
  color: var(--text-colour);
}

.align-right { text-align: right; }

.align-center { text-align: center; }

a {
  color: $brand-secondary-colour;
  outline: 0;
  text-decoration: none;

  &:hover {
    color: $brand-primary-colour;
    outline: 0;
    text-decoration: underline;
  }

  &:active {
    outline: 0;
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}

code,
pre {
  color: var(--text-colour);
  font-family: $monospace-font-family;
  font-size: 100%;
}

pre {
  white-space: pre-wrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

var {
  font-family: $monospace-font-family;
  font-weight: bold;
}

.uppercase { text-transform: uppercase; }

p.center { text-align: center; }

.heading-with-controls {
  align-items: center;
  display: flex;
  gap: 2rem;

  margin-bottom: .5rem;

  h3 {
    margin: 0;
  }
}

.text-muted {
  color: var(--text-colour-muted) !important;
}

.data-source-hint {
  align-items: center;
  display: flex;
  flex-direction: column;

  .inner {
    background: rgba(#888, .2);
    border-radius: 20px;
    opacity: .5;
    padding: .5rem 1rem;
  }
}

.percentage-change, .percentage-difference {
  color: var(--text-colour);
  font-size: .75rem;
  font-weight: normal;
  margin: 0 0 0 .5rem;
  white-space: nowrap;

  &.good {
    color: var(--text-colour-success);
  }

  &.bad {
    color: var(--text-colour-danger);
    opacity: .9;
  }
}

.score {
  border: 1px dashed var(--score-border-colour);
  border-radius: 50%;
  color: var(--score-colour);
  display: inline-block;
  font-family: $labels-font-family;
  height: 30px;
  line-height: 28px;
  text-align: center;
  width: 30px;

  &.bad {
    border: 1px dashed var(--score-bad-colour);
    color: var(--score-bad-colour);
  }

  &.medium {
    border: 1px dashed var(--score-medium-colour);
    color: var(--score-medium-colour);
  }

  &.good {
    border: 1px dashed var(--score-good-colour);
    color: var(--score-good-colour);
  }
}

.score-label {
  &.bad {
    color: var(--score-bad-colour);
  }

  &.medium {
    color: var(--score-medium-colour);
  }

  &.good {
    color: var(--score-good-colour);
  }
}

.label-tags {
  margin: 0;
  text-transform: uppercase;

  li {
    margin: 4px;

    span {
      background: var(--label-tags-colour);
      border-radius: 2px;
      color: $white;
      display: inline-flex;
      align-items: center;
      gap: .2rem;
      font-weight: normal;
      padding: 3px 7px;
      white-space: nowrap;

      &.info {
        background: var(--label-tags-info-colour);
        color: $white;
      }

      &.success {
        background: var(--label-tags-success-colour);
        color: var(--label-tags-success-text-colour);
      }

      &.warning {
        background: var(--label-tags-warning-colour);
        color: $black;
      }

      &.danger {
        background: var(--label-tags-danger-colour);
        color: $white;
      }
    }
  }

  &.small {
    li {
      font-size: 85%;
    }
  }
}

.recommendation-value {
  font-size: .75rem;
  font-weight: normal;
  white-space: nowrap;
  border-radius: 5px;
  padding: .1rem .5rem;
  vertical-align: middle;
  width: auto;
  display: inline-block;
  background: rgba(#777, .2);
  color: var(--text-colour) !important;
  transition: color .5s ease;

  &.good {
    background: #3e8938;
    color: $white !important;
  }

  &.bad {
    background: #8f111d;
    color: $white !important;;
  }

  i { margin-right: .3rem; }
}

.text-with-icon {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: .5rem;

  img {
    border-radius: 5px;
    height: 32px;
    width: 32px;

    &.size-16 {
      border-radius: 3px;
      height: 16px;
      width: 16px;
    }

    &.size-20 {
      border-radius: 4px;
      height: 20px;
      width: 20px;
    }

    &.size-24 {
      border-radius: 4px;
      height: 24px;
      width: 24px;
    }
  }
}

.try-it {
  background: var(--callout-bg-colour);
  margin: 2rem 0;
  padding: 1.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 3rem;

  .description {
    flex-grow: 1;

    h3 { font-size: 1.4rem; }

    p { margin: 1rem 0 0; }

    .detail-with-icon {
      display: flex;
      gap: 1rem;

      i {
        margin: 1rem 0 0;
        font-size: 40px;
        opacity: .5;
      }
    }
  }

  button {
    width: 250px;
    margin-right: 1rem;
  }
}
