@import 'colours';
@import 'fonts';

.badge-info {
  color: var(--badge-info-colour);
  background-color: var(--badge-info-bg-colour);
}

.subtle-badge {
  vertical-align: middle;
  background: var(--badge-subtle-bg-colour);
  display: inline-block;
  padding: 0 3px;
  border-radius: 3px;
  margin-left: 1rem;
}

em.bubble {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px dashed var(--bubble-border-colour);
  margin-right: 5px;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 5px;
  color: var(--bubble-colour);
  vertical-align: top;

  &.centered {
    margin-right: 0;
  }

  &.small {
    height: 16px;
    width: 16px;
    position: relative;
    top: 3px;
    font-size: 10px;
    line-height: 15px;
  }

  &.large {
    height: 28px;
    width: 28px;
    padding-top: 1px;
  }

  &.green {
    background: var(--bubble-green-bg-colour);
    border: 1px solid var(--bubble-green-bg-colour);
    color: var(--bubble-green-colour);
  }

  &.yellow {
    background: var(--bubble-yellow-bg-colour);
    border: 1px solid var(--bubble-yellow-bg-colour);
    color: var(--bubble-yellow-colour);
  }

  &.red {
    background: var(--bubble-red-bg-colour);
    border: 1px solid var(--bubble-red-bg-colour);
    color: var(--bubble-red-colour);
  }

  &.grey {
    background: var(--bubble-grey-bg-colour);
    border: 1px solid var(--bubble-grey-bg-colour);
    color: var(--bubble-grey-colour);
  }
}

.internal-accreditation-badge {
  border-radius: 5px;
  padding: .2rem .5rem;
  color: $black;
  white-space: nowrap;
  vertical-align: middle;
  width: auto;
  font-family: $brand-font-family;
  text-transform: uppercase;
  display: inline-block;

  &.gold {
    background: #a3a15a;
  }

  &.silver {
    background: #c4c4bd;
  }

  &.bronze {
    background: #a8835e;
  }

  span {
    vertical-align: middle;
    display: inline-block;

    &.position {
      margin-left: .7rem;
      font-weight: bold;
    }
  }
}

.icon-text-tag {
  border-radius: 5px;
  padding: .2rem .5rem;
  color: $black;
  white-space: nowrap;
  vertical-align: middle;
  width: auto;
  text-transform: uppercase;
  display: inline-block;

  &.no-text-transform {
    text-transform: initial;
  }

  &.grey {
    background: #ccc;
  }

  &.green {
    background: #3e8938;
    color: $white;
    i { color: $white; }
  }

  &.yellow {
    background: #b8810c;
    color: $white;
    i { color: $white; }
  }

  &.red {
    background: #8f111d;
    color: $white;
    i { color: $white; }
  }

  .count {
    font-style: normal;
    background: rgba(#fff, .4);
    border-radius: 4px;
    display: inline-block;
    margin: .1rem 0 .1rem .25rem;
    padding: 0 .25rem;
    font-size: 85%;
  }
}
