// Reset. Consider replacing with normalize?
@import 'reset';

// Declare theses early, because they set variables used in vendor libraries
@import 'fonts';

// Vendor libraries
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss';
@import 'node_modules/daterangepicker/daterangepicker.css';
@import 'node_modules/ngx-toastr/toastr-bs5-alert';
@import 'node_modules/@ng-select/ng-select/scss/default.theme';
@import 'node_modules/ngx-ui-switch/ui-switch.component';

@import 'bootstrap-overrides';
@import 'toastr-overrides';
@import 'ng-select-overrides';

@import 'colours';
@import 'theme';
@import 'functions';
@import 'mixins';
@import 'selectors';
@import 'generic';
@import 'layout';
@import 'tiles';
@import 'key-stats';
@import 'text';
@import 'badges';
@import 'tooltips';
@import 'forms';
@import 'controls';
@import 'charts';
@import 'picklist';
@import 'modal';
@import 'identity-popover';
@import 'content-header-controls';
@import 'daterangepicker';
@import 'scrollbars';
@import 'tables';
