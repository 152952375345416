@import 'colours';
@import 'fonts';

.modal {
  z-index: 3050;
}

.modal-content {
  background: var(--modal-bg-colour);
  box-shadow: var(--modal-box-shadow);
  border: var(--modal-border);
  color: var(--text-colour);
}

.modal-header {
  border-bottom: 1px solid var(--modal-divider-colour);
}

.modal-footer {
  border-top: 1px solid var(--modal-divider-colour);
}

.modal-footer-content {
  display: flex;
  width: 100%;

  > .left {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
  }

  > .right {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal-help {
  line-height: 2.3rem;
  margin-left: 1rem;
}

.modal-body {
  &.no-padding {
    padding: 0;
  }

  .picklist {
    max-height: 70vh;
    overflow: auto;
    padding: 1rem;

    &.fixed-height {
      height: 70vh;
    }
  }
}

.modal-index-detail {
  max-width: 656px;

  .modal-header {
    border-bottom: 0;
    padding: 1.5rem 1.5rem 0;

    h4 {
      font-size: 40px;
      font-family: $brand-font-family;
    }

    .close { font-size: 2.5rem; }
  }

  .modal-body {
    padding: 0 1.5rem;
  }

  .modal-footer {
    border-top: 0;
    padding: 1.5rem;
  }
}

//noinspection CssUnknownTarget
.modal-index-explain {
  width: 1050px;
  max-width: 1050px;
  min-width: 1050px;
  font-family: $brand-font-family;

  .modal-header {
    border-bottom: 0;
    padding: 2.5rem 2.5rem 1.5rem;

    h4 {
      font-size: 55px;
      line-height: 70px;

      .super {
        vertical-align: super;
        font-size: 22px;
      }
    }

    .close {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      line-height: 1rem;
      font-size: 2.5rem;
    }

    .logo {
      position: absolute;
      right: 4rem;
      top: 3.7rem;
      height: 42px;
    }
  }

  .modal-body {
    padding: 0 3.2rem 0 2.5rem;

    article {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      section {
        width: 313px;
        display: flex;
        flex-direction: column;

        h5 {
          width: 313px;
          height: 79px;
          line-height: 79px;
          text-align: center;
          color: $white;
          font-size: 19px;
          font-weight: bold;
          text-transform: uppercase;
          position: relative;
          background-position-x: -10px;
        }

        &.quality h5 {
          background: url('/assets/images/ua-index/explain-quality.svg');
        }

        &.transparency h5 {
          background: url('/assets/images/ua-index/explain-transparency.svg');
        }

        &.trust h5 {
          background: url('/assets/images/ua-index/explain-trust.svg');
        }

        .text {
          background: var(--ua-index-explain-text-bg-colour);
          padding: 3rem 1.5rem .5rem;
          flex-grow: 1;
          margin: -40px 19px 0 10px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        p { font-size: 15px; }
      }
    }
  }

  .modal-footer {
    border-top: 0;
    padding: 2.5rem;

    p { padding: 0 20px; }
  }
}

.modal-index-upgrade {
  width: 550px;
  max-width: 550px;
  min-width: 550px;
  font-family: $brand-font-family;

  .modal-header {
    border-bottom: 0;
    padding: 2.5rem 2.5rem 1.5rem;

    .modal-title {
      flex-grow: 1;
      text-align: center;

      .logo {
        height: 25px;
      }
    }

    .close {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      line-height: 1rem;
      font-size: 2.5rem;
    }
  }

  .modal-body {
    padding: 2rem 4rem 0;
    text-align: center;

    p.phone {
      margin-top: 2rem;

      strong {
        display: inline-block;
        margin-top: 1rem;
        font-size: 125%;
      }
    }
  }

  .modal-footer {
    border-top: 0;
    padding: 1.5rem 0 2.5rem;
    text-align: center;
    flex-grow: 1;

    .modal-footer-content {
      display: block;
    }

    a.btn {
      font-family: $brand-font-family;
      padding: 1.5rem 3rem;
      height: auto;
      font-weight: bold;
      margin: 0 0 2rem;
    }
  }
}

.message-box {
  .modal-footer {
    justify-content: center;
    padding: 1.25rem;

    .btn {
      margin: 0 .6rem;
      min-width: 100px;
    }
  }
}
