@import 'colours';
@import 'fonts';

.pie-and-timeseries {
  overflow: hidden;
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr;
}

highcharts-chart {
  display: block;
  width: 100%;
  border-radius: 5px;

  &.auto-width {
    width: auto;
  }
}

.quality-mini-bar-chart {

  width: 50px;
  display: inline-block;
  vertical-align: middle;

  .valid,
  .suspicious,
  .invalid {
    margin: 2px;
    height: 8px;
    border-radius: 1px 3px 3px 1px;
  }

  .valid { background: #018b0e linear-gradient(to bottom, #018b0e 0%, #007a0b 100%); }

  .suspicious { background: #e0a331 linear-gradient(to bottom, #e0a331 0%, #b8810c 100%); }

  .invalid { background: #a91523 linear-gradient(to bottom, #a91523 0%, #8f111d 100%) !important; }
}

.headline.pie {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline-pie-chart-unavailable {
  width: 220px;
  margin: 22px 0 0;
}

.chart-annotation {
  font-family: $chart-annotation-font-family;

  h4 {
    font-size: .8rem;
    font-weight: bold;
    padding: 0;
    margin: 0 0 .1rem;
  }
}

.chart-floating-axis-title {
  font-family: $chart-axis-font-family;
  color: var(--chart-floating-axis-title-colour);
  background: var(--chart-floating-axis-title-bg-colour);
  border: 1px solid var(--chart-floating-axis-title-border-colour);
  border-bottom: 0;
  padding: .1rem .2rem;
  border-radius: 3px 3px 0 0;
}

table.chart-key-stats {
  th,
  td {
    font-family: $chart-annotation-font-family;
    color: var(--text-colour);
  }

  th {
    padding-right: .5rem;
  }
}

.chart-tooltip {
  font-family: $chart-tooltip-font-family;
  background: var(--chart-tooltip-bg-colour);
  opacity: .95;
  padding: .4rem .6rem;
  border: 1px solid var(--chart-tooltip-border-colour);
  box-shadow: var(--chart-tooltip-box-shadow);
  color: var(--text-colour);
  font-weight: var(--chart-tooltip-font-weight);
  border-radius: 5px;

  h3 {
    font-weight: bold;
    font-size: .9rem;
    padding: 0;
    margin: 0;
    display: flex;
    gap: .5rem;
    align-items: center;

    img {
      border-radius: 4px;
      width: 16px;
      height: 16px;
    }

    span.bullet {
      display: inline-block;
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      border-radius: 4px;
      vertical-align: middle;
      position: relative;
      top: -2px;
      background: rgba(#777, .5);
    }
  }

  h4 {
    font-weight: bold;
    font-size: .8rem;
    padding: 0;
    margin: 0;
  }

  h5 {
    font-weight: bold;
    font-size: .8rem;
    padding: 0;
    margin: 0 0 .3rem;
  }

  .score {
    width: auto;
    height: auto;
    min-width: 1.25rem;
    border-radius: 3px;
    padding: 0 .2rem;
    text-align: center;
    line-height: initial;
    font-family: inherit;
  }

  .quality-mini-progress-bar {
    display: flex;
    width: 70px;

    span {
      height: 5px;

      &:first-child { border-radius: 1px 0 0 1px; }

      &:last-child { border-radius: 0 1px 1px 0; }

      &:not(:first-child) { margin-left: 1px; }

      &.bad { background: var(--score-bad-colour); }

      &.medium { background: var(--score-medium-colour); }

      &.good { background: var(--score-good-colour); }
    }
  }

  table {
    margin-top: .5rem;

    thead {
      background: var(--chart-tooltip-header-bg-colour);

      th {
        font-weight: bold;
        padding: .1rem .4rem .1rem 0;

        &:first-child {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        &:last-child {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }

    tbody {
      th {
        padding: .1rem 1rem .1rem 0;

        > div {
          display: flex;
          align-items: center;
          gap: .1rem;
        }
      }

      td {
        padding: .1rem 1rem .1rem 0;
        font-weight: bold;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    span.bullet {
      display: inline-block;
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      border-radius: 3px;
      vertical-align: middle;
      margin-right: .4rem;
      background: rgba(#777, .5);
    }
  }
}

.chart-no-data {
  flex-grow: 1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  &.tall {
    height: 350px;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
    color: var(--chart-no-data-colour);
    font-weight: bold;
    font-size: 120%;
  }

  span {
    i {
      display: inline-block;
      color: var(--chart-no-data-colour);
      margin-bottom: .5rem;
    }

    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 120%;
    color: var(--chart-no-data-colour);
  }
}

.highcharts-tooltip-container {
  z-index: 999999 !important;
}

.inline-donut-headline {
  color: var(--text-colour);
  text-align: center;

  a {
    text-decoration: none;
    color: var(--text-colour) !important;
  }

  strong {
    display: block;
    font-size: 2rem;
    font-family: $brand-font-family;
    line-height: 2rem;
  }

  span {
    font-size: .8rem;
    font-family: $labels-font-family;
    line-height: 1rem;
  }

  &.semi {
    strong {
      font-size: 1.5rem;
    }
  }

  &.small {
    strong {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    span {
      font-size: .7rem;
      line-height: .8rem;
    }
  }

  &.mini {
    strong {
      font-size: 1.1rem;
      line-height: 1.1rem;

      &.shrink {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }
}
