@import 'colours';
@import 'fonts';

.picklist {
  margin: 0;

  &.no-top-padding {
    padding-top: 0;
  }

  > ul {
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, 1fr));
    gap: .5rem 2rem;

    &.full-width {
      grid-template-columns: 1fr;
    }

    li {
      border-radius: 5px;
      padding: .5rem 1rem;
      margin: 0;
      display: flex;
      gap: .75rem;
      flex-wrap: nowrap;
      align-items: center;
      background: var(--modal-picklist-item-bg-colour);
      border: 1px solid var(--modal-picklist-item-bg-colour);
      cursor: pointer;

      img.icon {
        border-radius: 5px;
        width: 42px;
        height: 42px;
        margin-left: -.5rem;
      }

      i { opacity: .3; }

      &.selected {
        border: 1px solid $brand-secondary-colour;

        i {
          opacity: 1;
          color: $brand-secondary-colour;
        }
      }

      .name {
        flex-grow: 1;
      }

      .tags {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-content: flex-end;

        ul {
          margin: 0;
          padding: 0;

          li {
            border-radius: 4px;
            padding: 0 .4rem;
            background: rgba(#777, .2);
          }
        }
      }
    }
  }
}
