@import 'fonts';

.tiles {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  &.chart {
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));

    &.two-columns {
      grid-template-columns: minmax(650px, calc(50vw - 40px - 1rem - 65px)) minmax(650px, calc(50vw - 40px - 1rem - 65px));
    }
  }

  &.full-width {
    grid-template-columns: 1fr;
  }

  .tile {
    background: var(--tile-bg-colour);
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    &.spacer { background: transparent; }

    &.no-data {
      padding: 2rem;
      display: flex;
      align-items: center;

      p {
        margin: 0 2rem 0 0;
        font-size: 1.2rem;
        flex-grow: 1;
      }
    }

    &.controls {
      padding: 1rem;
    }

    .tile-padding {
      padding: 20px;
    }

    > header {
      padding: .5rem 1rem;
      border-bottom: 1px solid var(--tile-header-divider-colour);
      min-height: 3rem;
      display: flex;
      align-items: center;

      a {
        text-decoration: none !important;
        color: var(--text-colour) !important;
        transition: color .5s ease;

        &:hover {
          color: var(--text-colour-strong) !important;
        }
      }

      i.fab,
      i.far,
      i.fa-solid {
        font-size: 2rem;
        display: inline-block;
        margin-right: .5rem;
        vertical-align: middle;
      }

      label {
        margin: 0 .5rem 0 0;
      }

      ng-select {
        min-width: 150px;
      }

      h3 {
        display: inline;
        vertical-align: middle;
        font-size: 1.2rem;
        margin: 0;
        flex-grow: 1;

        span.sub-title {
          margin-left: 1rem;
          font-size: .9rem;
          opacity: .5;
          vertical-align: middle;
          position: relative;
          top: -1px;
        }
      }

      .control-with-label {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: .5rem;

        .label {
          font-family: $labels-font-family;
          opacity: .5;
        }

        .btn-group {
          label {
            margin: 0;
            line-height: 1.5rem;
            padding: 0 1rem;
            height: auto;
            font-size: .9rem;
            text-transform: none;
          }
        }
      }
    }

    > .icon {
      justify-self: left;
    }

    img.icon,
    a.icon img {
      height: 100px;
      width: 100px;
      border-radius: 5px;
      margin-right: 10px;
    }

    > section {
      padding: 1rem;
    }

    app-data-table table {
      border-radius: 0 0 5px 5px !important;
    }
  }
}
