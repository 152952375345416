@import 'colours';
@import 'fonts';

.ng-select {
  font-family: $form-controls-font-family;
  font-size: 16px;

  .ng-select-container {
    background-color: var(--ng-select-container-bg-colour);
    border: 1px solid var(--ng-select-container-border-colour);
    color: var(--text-colour);
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: var(--form-control-disabled-bg-colour);
    border: 1px solid var(--form-control-disabled-border-colour);
  }

  &.ng-select-opened > .ng-select-container {
    background: var(--ng-select-container-bg-colour);
    border-color: var(--ng-select-container-focused-border-colour);
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: 1px solid var(--ng-select-container-focused-border-colour) !important;
  }

  .ng-dropdown-header {
    border-bottom: 1px solid var(--ng-dropdown-header-border-colour);
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    &.left { border-right: 1px solid var(--ng-select-value-divider-colour); }

    &:hover { background-color: #4f93ce; }
  }

  &.ng-select-single {
    .ng-select-container {
      height: calc(1.5em + .75rem + 2px);
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      min-height: calc(1.5em + .75rem + 2px);

      .ng-value-container {
        .ng-value {
          background: $secondary-colour;
          color: $white;
        }

        .ng-value-icon:hover {
          border-radius: 2px 0 0 2px;
          background: lighten($secondary-colour, 10%);
        }
      }
    }
  }

  &.no-dropdown {
    .ng-arrow-wrapper { display: none !important; }
  }

  input[type='checkbox'] {
    position: relative;
    top: 2px;
  }
}

.ng-dropdown-panel {
  background: var(--ng-dropdown-panel-bg-colour);
  border: 1px solid var(--ng-dropdown-panel-border-colour);

  &.ng-select-top {
    border-top-color: var(--ng-dropdown-panel-border-top-bottom-colour);
    border-bottom-color: var(--ng-dropdown-panel-border-top-bottom-colour);
  }

  &.ng-select-bottom {
    border-top-color: var(--ng-dropdown-panel-border-top-bottom-colour);
    border-bottom-color: var(--ng-dropdown-panel-border-top-bottom-colour);
  }

  .ng-dropdown-panel-items {
    .ng-option {
      color: var(--text-colour);
      background-color: var(--ng-dropdown-panel-bg-colour);

      &.ng-option-marked {
        background-color: var(--ng-option-marked-bg-colour);
        color: var(--text-colour);
      }

      &.ng-option-selected {
        background-color: var(--ng-option-marked-bg-colour);
        color: var(--text-colour);

        &.ng-option-marked {
          background-color: var(--ng-option-marked-bg-colour);
          color: var(--text-colour);
        }
      }
    }
  }
}

.ng-option,
.ng-dropdown-header,
.ng-dropdown-footer {
  font-family: $form-controls-font-family;
}

span.ng-option-label {
  display: inline-block;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border: 1px solid #ccc;
  box-shadow: none;
}

.filters,
.compact {
  .ng-select {
    .ng-select-container {
      background: none;
      border: 1px solid var(--page-top-filters-form-control-border-colour);
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 4px 10px;
  }

  .ng-option,
  .ng-dropdown-header,
  .ng-dropdown-footer {
    font-size: 14px;
  }

  .ng-value-label {
    font-size: 13px;
  }

  .ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: 1px solid var(--page-top-filters-form-control-border-colour);
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  text-overflow: unset !important;
  overflow: visible !important;
  white-space: unset !important;
  display: flex !important;
  word-break: break-all;

  input[type='checkbox'] {
    margin-top: 3px;
    margin-right: .4rem;
  }
}

.ng-select .small-inline-icon,
.ng-dropdown-panel .small-inline-icon {
  display: flex;
  align-items: center;
  gap: .5rem;

  img {
    border-radius: 4px;
    width: 24px;
    height: 24px;
  }
}

.ng-select .large-inline-icon,
.ng-dropdown-panel .large-inline-icon {
  display: flex;
  align-items: center;
  gap: .5rem;

  img {
    border-radius: 5px;
    width: 48px;
    height: 48px;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  color: var(--text-colour);
}
