@import 'fonts';
@import 'colours';

.key-stats {
  margin-bottom: 2rem;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  tr {
    padding: 0 20px;
    margin: 0;
    height: 1px;

    transition: background .5s ease;

    td {
      background: var(--key-stats-bg-colour);
      background-clip: padding-box;
      vertical-align: middle;
      text-align: center;
      padding: .5rem 1rem;
      height: inherit;
    }

    &.header {
      text-align: left;

      td {
        background: var(--key-stats-bg-colour);
        border-radius: 5px 5px 0 0;
        padding: 0;
      }

      header {
        padding: .5rem 1rem;
        border-bottom: 1px solid var(--key-stats-header-divider-colour);
        min-height: 3rem;
        display: flex;
        align-items: center;
        margin: 0;
        border-radius: 5px 5px 0 0;
        overflow: hidden;

        .spacer {
          flex-grow: 1;
        }

        button.pin {
          border: 0;
          background: rgba(#999, .2);
          color: var(--text-colour);
          position: relative;
          left: -1rem;
          top: -.5rem;
          align-self: start;
          border-bottom-right-radius: 5px;
          width: 2rem;
          height: 2rem;
          transition: background .5s ease;

          i {
            font-size: 1rem;
            transform: rotate(-45deg);
            opacity: .6;
            transition: opacity .5s ease, transform .5s ease;
          }

          &:hover {
            background: rgba(#999, .4);

            i {
              opacity: 1;
              transform: rotate(0deg);
            }
          }

          &.pinned {
            background: $brand-primary-colour !important;
            color: $white;

            i {
              opacity: 1;
              transform: rotate(0deg);
            }
          }
        }

        a {
          text-decoration: none !important;
          color: var(--text-colour) !important;
          transition: color .5s ease;

          &:hover {
            color: var(--text-colour-strong) !important;
          }
        }

        .title-icon {
          font-size: 2rem;
          display: inline-block;
          margin-right: .5rem;
          vertical-align: middle;
        }

        h3 {
          display: inline-block;
          vertical-align: middle;
          text-align: left;
          font-size: 1.2rem;
          margin: 0;
          flex-grow: 1;
        }
      }
    }

    &.pinned {
      td { background: var(--key-stats-pinned-bg-colour); }
    }

    td.top-left { border-radius: 5px 0 0 0; }

    td.top-right { border-radius: 0 5px 0 0; }

    td.bottom-left { border-radius: 0 0 0 5px; }

    td.bottom-right { border-radius: 0 0 5px 0; }

    td.row-label {
      text-align: left;
      font-family: $labels-font-family;

      strong {
        padding: .1rem 0 .1rem 1rem;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        span { font-size: 110%; }

        i { opacity: .3; margin-right: 1rem; font-size: 120%; }
      }
    }

    &.spacer {
      td {
        background: transparent;
        height: 2rem;
      }
    }
  }

  td.icon {
    text-align: left;
    width: 1px;

    img {
      height: 100px;
      width: 100px;
      border-radius: 5px;
    }
  }

  td.title {
    padding-bottom: 0;

    > span {
      font-size: 1rem;
      opacity: .7;
      font-family: $labels-font-family;
      display: inline-block;
      top: 1rem;
      padding-top: .5rem;
      white-space: nowrap;
    }
  }

  td.first {
    padding-left: 2rem;

    &.key-stat-value > div, &.key-stat-value > span {
      padding-left: 1rem;
    }

    &.mini-trend-line highcharts-chart { margin-left: 1rem; }
  }

  td.last {
    padding-right: 2rem;

    &.key-stat-value > div, &.key-stat-value > span {
      padding-right: 1rem;
    }

    &.mini-trend-line highcharts-chart { margin-right: 1rem; }
  }

  td.key-stat-value {
    padding-left: 0;
    padding-right: 0;

    &.warning { color: var(--text-colour-warning); }

    &.good { color: var(--text-colour-success); }

    &.bad { color: var(--text-colour-danger); }

    > div {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.trend-line-hover {
        em.percentage-change, em.percentage-difference {
          display: none;
        }
      }
    }

    > span, > div > span:first-child, span.wrapped {
      font-size: 1.6rem;
      font-weight: bold;
      display: inline-block;
    }

    > span, > div > span {
      display: flex;
      justify-content: center;
    }

    em.percentage-change, em.percentage-difference {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      font-family: $labels-font-family;
      font-size: .75rem;
      font-weight: normal;
      margin: 0 0 0 .5rem;

      i {
        line-height: .5;
      }
    }

    .with-legend {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: .5rem;
      justify-content: center;

      i.legend {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 4px;
      }
    }
  }

  &.with-trend-lines {
    td.key-stat-value {
      padding-bottom: 0;
    }
  }

  td.mini-trend-line {
    text-align: center;
    padding: 0;

    highcharts-chart {
      width: auto;
      border-radius: 0;
      display: inline-block;
    }

    .placeholder {
      width: 100px;
      height: 40px;
    }
  }

  td.divider {
    margin: 0;
    padding: 1.5rem 1rem;

    > span {
      display: inline-block;
      width: 1px;
      height: 100%;
      border-left: 1px solid var(--key-stats-divider-colour);
    }

    &.pinned {
      span { border-left: 1px solid var(--key-stats-pinned-divider-colour); }
    }
  }

  tr.stacked {
    td.key-stat-value {
      padding-bottom: 1rem;

      > div {
        flex-direction: column;

        .percentage-change, .percentage-difference {
          margin: 0;
          flex-direction: row;
          gap: .3rem;
        }
      }
    }
  }

  tr.second-row {
    td {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;

      &.divider {
        > span { height: 30px; }
      }

      &.key-stat-value {
        > div {
          top: 0;

          &.trend-line-hover {
            * { opacity: .3; }
          }
        }

        span {
          color: var(--text-colour);
        }
      }
    }
  }

  tr.footer-detail {
    td {
      padding-top: 0;

      span + small {
        margin-left: .5rem;
      }

      small {
        display: inline-grid;
        margin-bottom: .5rem;
        grid-template-columns: min-content 1fr;
        gap: 0 .5rem;
        white-space: nowrap;
        opacity: .5;
        font-family: $labels-font-family;

        .label {
          grid-column: 1;
          text-align: right;
        }

        .value {
          grid-column: 2;
          text-align: left;
        }
      }
    }
  }

  tr.hr {
    td {
      padding: 0;

      hr {
        margin: 0 1.5rem;
        border-top: 1px dashed var(--key-stats-horizontal-divider-colour);
      }
    }
  }

  td.chart {
    padding: .5rem;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      highcharts-chart {
        display: inline-block;
        width: 150px;
        justify-self: center;
      }

      &.semi-donut {
        highcharts-chart {
          margin: -10px 0;
        }
      }
    }
  }

  td.actions {
    > div {
      grid-column: actions-start;
      display: grid;
      align-items: center;
      justify-items: end;

      ul {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        margin: 0;
      }
    }
  }
}

.tile .key-stats {
  margin-bottom: 20px;

  tr {
    &:first-child {
      td {
        padding: .5rem .5rem 0;

        span {
          padding: 0;
        }
      }
    }

    &:last-child {
      td {
        padding: 0 .5rem .5rem;
      }
    }

    td {
      background: var(--chart-tiled-bg-colour);

      &.divider {
        span {
          height: 50px;
        }
      }
    }
  }

}
